import React from "react";
import modelData from './modelData';
import productData from './productData';

function ProductInfo(props) {
  const theProduct = productData.find(p => p.id === props.productId);
  const theModel = modelData.find(m => m.id === theProduct.modelId);

  return (
    <div id="product-info">
      <p><span className="info-label">Material</span> { theProduct.material ? theProduct.material : (theModel.material ? theModel.material : "n/a") }</p>
      <p><span className="info-label">Exterior</span> { (theProduct.sizes && theProduct.sizes.ext) ? theProduct.sizes.ext.join(' × ') : ((theModel.sizes && theModel.sizes.ext) ? theModel.sizes.ext.join(' × ') : "n/a") }</p>
      <p><span className="info-label">Interior</span> { (theProduct.sizes && theProduct.sizes.int) ? theProduct.sizes.int.join(' × ') : ((theModel.sizes && theModel.sizes.int) ? theModel.sizes.int.join(' × ') : "n/a") }</p>
    </div>
  );
}


export default ProductInfo;