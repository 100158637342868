import React from "react";
import PhotoSwipeExt from './PhotoSwipeExt';

import productData from './productData';

class ProductImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,

      options: {
        modal: false,
        closeOnScroll: false,
        pinchToClose: false,
        closeOnVerticalDrag: false,
        escKey: false,
        history: false,
      },

    };

    this.handleClose = () => {
      this.setState({
        isOpen: false
      });
    };
  }

  render() {
    return (
      <React.Fragment>
        <div id="product-image-wrapper">
          <PhotoSwipeExt isOpen={this.state.isOpen} items={productData.find(p => p.id === this.props.productId).items} options={this.state.options} onClose={this.handleClose} />
        </div>

      </React.Fragment>
    );
  }
}

export default ProductImage;