import React from "react";
//import modelData from './modelData';
import productData from './productData';

class ColourSwitcher extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      productId: "", // to avoid setState loop
      productItems: [],
    };
    
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.fetchItems();
  }

  componentDidUpdate() {
    this.fetchItems();
  }

  fetchItems() {
    const context = this;

    if (this.props.productId === "" || this.props.productId !== this.state.productId) {

      this.setState({ productId: this.props.productId });
      const modelId = productData.find(p => p.id === this.props.productId).modelId;
      const prodInRange = productData.filter(p => p.modelId === modelId);

      this.setState( { productItems: prodInRange.map(function(object, i) {
        return ({
          id: object.id,
          image: object.thumbnail,
          isActive: object.id === context.props.productId
        });
      }) });
    };
  }

  handleClick(e) {
    this.props.onProductChange(e.target.value);
  }

  render() {
    const context = this;

    return (
      <React.Fragment>
        <div id="colour-switcher">
          {this.state.productItems.map(function(object, i) {
            return <div key={i} className={object.isActive ? 'btn-wrapper active' : 'btn-wrapper'}>
                    <button className="btn-colour-picker" value={object.id} onClick={context.handleClick} style={{ backgroundImage: 'url(' + object.image + ')' }}>                
                    </button>
                    </div>
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default ColourSwitcher;