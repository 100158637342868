import React from "react";
import modelData from './modelData';
import productData from './productData';

function ProductName(props) {
  const theProduct = productData.find(p => p.id === props.productId);
  const theModel = modelData.find(m => m.id === theProduct.modelId);

  return (
    <div id="product-name">
      <h1>
        { theModel.name }
      </h1>
      <p>{ theProduct.name.length > 0 ? theProduct.name : "" } {theModel.description ? theModel.description : "" }</p>
    </div>
  );
}


export default ProductName;