import React from 'react';
import PropTypes from 'prop-types';
import Photoswipe from 'photoswipe';
import '../assets/photoswipe/photoswipe.css';
import '../assets/photoswipe/default-skin/default-skin.css';
import classnames from 'classnames';
import events from 'react-photoswipe/src/events';

class PhotoSwipeExt extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    options: PropTypes.object,
    onClose: PropTypes.func,
    id: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    options: {},
    onClose: () => {
    },
    id: '',
    className: ''
  };

  state = {
    isOpen: this.props.isOpen
  };

  componentDidMount = () => {
    const { isOpen } = this.state;
    if (isOpen) {
      this.openPhotoSwipe(this.props);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    const { isOpen } = this.state;
    if (nextProps.isOpen) {
      if (!isOpen) {
        this.openPhotoSwipe(nextProps);
      } else {
        this.updateItems(nextProps.items);
      }
    } else if (isOpen) {
      this.closePhotoSwipe();
    }
  };

  componentWillUnmount = () => {
    this.closePhotoSwipe();
  };

  openPhotoSwipe = (props) => {
    const { items, options } = props;
    const pswpElement = this.pswpElement;

    // pass cloned copy of items to PhotoSwipe ctor to stop it from "eating"
    // the original items from ProductData.
    const clonedItems = items.map(a => Object.assign({}, a));
    this.photoSwipe = new Photoswipe(pswpElement, false, clonedItems, options);
    
    events.forEach((event) => {
      const callback = props[event];
      if (callback || event === 'destroy') {
        const self = this;
        this.photoSwipe.listen(event, function (...args) {
          if (callback) {
            args.unshift(this);
            callback(...args);
          }
          if (event === 'destroy') {
            self.handleClose();
          }
        });
      }
    });
    this.setState({
      isOpen: true
    }, () => {
      this.photoSwipe.init();
    });
  };

  updateItems = (items = []) => {
    // reset current index if it exceeds the length of new items
    if (this.photoSwipe.getCurrentIndex() >= items.length) {
      this.photoSwipe.goTo(0);
    }
    this.photoSwipe.items.length = 0;
    items.forEach((item) => {
      this.photoSwipe.items.push(item);
    });
    this.photoSwipe.invalidateCurrItems();
    this.photoSwipe.updateSize(true);
  };

  closePhotoSwipe = () => {
    if (!this.photoSwipe) {
      return;
    }
    this.photoSwipe.close();
  };

  handleClose = () => {
    const { onClose } = this.props;
    this.setState({
      isOpen: false
    }, () => {
      if (onClose) {
        onClose();
      }
    });
  };

  render() {
    const { id } = this.props;
    let { className } = this.props;
    className = classnames(['pswp', className]).trim();
    return (
      <div
        id={id}
        className={className}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        ref={(node) => {
          this.pswpElement = node;
        }}
      >
        <div className="pswp__bg"/>
        <div className="pswp__scroll-wrap">
          <div className="pswp__container">
            <div className="pswp__item"/>
            <div className="pswp__item"/>
            <div className="pswp__item"/>
          </div>          
        </div>
      </div>
    );
  }
}

export default PhotoSwipeExt;