const productImages = require.context('../assets/images/products/', false, /.+\.jpg/);

const productData = [
  {
    id: 'C1',
    name: "White",
    thumbnail: productImages('./tbn-white.jpg'),
    modelId: 4,
    items: [
      {
        src: productImages('./c1-side.jpg'),
        w: 1388,
        h: 972,
        title: 'Side'
      },
      {
        src: productImages('./c1-front.jpg'),
        w: 1388,
        h: 972,
        title: 'Front'
      },
      {
        src: productImages('./c1-handles.jpg'),
        w: 1388,
        h: 972,
        title: 'Handles'
      },
      {
        src: productImages('./c1-internal.jpg'),
        w: 1388,
        h: 927,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C22',
    name: "White (half-lid)",
    thumbnail: productImages('./tbn-white-half.jpg'),
    modelId: 4,
    items: [
      {
        src: productImages('./c22-side.jpg'),
        w: 1388,
        h: 925,
        title: 'Side'
      },
      {
        src: productImages('./c22-front.jpg'),
        w: 1388,
        h: 925,
        title: 'Front'
      },
      {
        src: productImages('./c1-handles.jpg'),
        w: 1388,
        h: 972,
        title: 'Handles'
      },
      {
        src: productImages('./c22-internal.jpg'),
        w: 1388,
        h: 925,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C2',
    name: "Mahogany",
    thumbnail: productImages('./tbn-mahogany.jpg'),
    modelId: 4,
    items: [
      {
        src: productImages('./c2-side.jpg'),
        w: 1388,
        h: 972,
        title: 'Side'
      },
      {
        src: productImages('./c2-front.jpg'),
        w: 1388,
        h: 972,
        title: 'Front'
      },
      {
        src: productImages('./c2-handles.jpg'),
        w: 1388,
        h: 972,
        title: 'Handles'
      },
      {
        src: productImages('./c2-internal.jpg'),
        w: 1388,
        h: 927,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C3',
    name: "Mahogany",
    thumbnail: productImages('./tbn-mahogany.jpg'),
    modelId: 5,
    items: [
      {
        src: productImages('./c3-side.jpg'),
        w: 1388,
        h: 972,
        title: 'Side'
      },
      {
        src: productImages('./c3-front.jpg'),
        w: 1388,
        h: 972,
        title: 'Front'
      },
      {
        src: productImages('./c3-handles.jpg'),
        w: 1388,
        h: 972,
        title: 'Handles'
      },
      {
        src: productImages('./c3-internal.jpg'),
        w: 1388,
        h: 927,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C4',
    name: "White",
    thumbnail: productImages('./tbn-white.jpg'),
    modelId: 5,
    items: [
      {
        src: productImages('./c4-side.jpg'),
        w: 1388,
        h: 972,
        title: 'Side'
      },
      {
        src: productImages('./c4-front.jpg'),
        w: 1388,
        h: 971,
        title: 'Front'
      },
      {
        src: productImages('./c4-handles.jpg'),
        w: 1388,
        h: 972,
        title: 'Handles'
      },
      {
        src: productImages('./c3-internal.jpg'),
        w: 1388,
        h: 972,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C5',
    name: "Maple",
    thumbnail: productImages('./tbn-maple.jpg'),
    modelId: 3,
    items: [
      {
        src: productImages('./c5-side.jpg'),
        w: 1388,
        h: 972,
        title: 'Side'
      },
      {
        src: productImages('./c5-front.jpg'),
        w: 1388,
        h: 972,
        title: 'Front'
      },
      {
        src: productImages('./c5-handles.jpg'),
        w: 1388,
        h: 972,
        title: 'Handles'
      },
      {
        src: productImages('./c5-internal.jpg'),
        w: 1388,
        h: 955,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C6',
    name: "Dark Rosewood",
    thumbnail: productImages('./tbn-dark-rosewood.jpg'),
    modelId: 2,
    items: [
      {
        src: productImages('./c6-side.jpg'),
        w: 1388,
        h: 972,
        title: 'Side'
      },
      {
        src: productImages('./c6-front.jpg'),
        w: 1388,
        h: 972,
        title: 'Front'
      },
      {
        src: productImages('./c6-handles.jpg'),
        w: 1388,
        h: 972,
        title: 'Handles'
      },
      {
        src: productImages('./c5-internal.jpg'),
        w: 1388,
        h: 972,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C7',
    name: "",
    thumbnail: productImages('./tbn-eco-nsna.jpg'),
    modelId: 8,
    items: [
      {
        src: productImages('./c7-side.jpg'),
        w: 1388,
        h: 971,
        title: 'Side'
      },
      {
        src: productImages('./c7-internal.jpg'),
        w: 1388,
        h: 971,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C8',
    name: "",
    thumbnail: productImages('./tbn-grand-ashley.jpg'),
    modelId: 6,
    items: [
      {
        src: productImages('./c8-side.jpg'),
        w: 1388,
        h: 971,
        title: 'Side'
      },
      {
        src: productImages('./c10-front.jpg'),
        w: 1388,
        h: 971,
        title: 'Front'
      },
      {
        src: productImages('./c8-handles.jpg'),
        w: 1388,
        h: 971,
        title: 'Handles'
      },
      {
        src: productImages('./c8-internal.jpg'),
        w: 1388,
        h: 927,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C9',
    name: "Rosewood",
    thumbnail: productImages('./tbn-mahogany.jpg'),
    modelId: 3,
    items: [
      {
        src: productImages('./c9-side.jpg'),
        w: 1388,
        h: 971,
        title: 'Side'
      },
      {
        src: productImages('./c9-front.jpg'),
        w: 1388,
        h: 971,
        title: 'Front'
      },
      {
        src: productImages('./c9-handles.jpg'),
        w: 1388,
        h: 971,
        title: 'Handles'
      },
      {
        src: productImages('./c5-internal.jpg'),
        w: 1388,
        h: 955,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C10',
    name: "Undersized",
    thumbnail: productImages('./tbn-grand-ashley.jpg'),
    modelId: 6,
    items: [
      {
        src: productImages('./c10-side.jpg'),
        w: 1388,
        h: 971,
        title: 'Side'
      },
      {
        src: productImages('./c10-front.jpg'),
        w: 1388,
        h: 971,
        title: 'Front'
      },
      {
        src: productImages('./c8-handles.jpg'),
        w: 1388,
        h: 971,
        title: 'Handles'
      },
      {
        src: productImages('./c8-internal.jpg'),
        w: 1388,
        h: 927,
        title: 'Internal'
      },
    ],
    sizes: {
      ext: [1850, 600, 560],
      int: [1680, 460, 345]
    }
  },
  {
    id: 'C11',
    name: "",
    thumbnail: productImages('./tbn-white.jpg'),
    modelId: 7,
    items: [
      {
        src: productImages('./c11-side.jpg'),
        w: 1388,
        h: 971,
        title: 'Side'
      },
      {
        src: productImages('./c11-side-2.jpg'),
        w: 1388,
        h: 971,
        title: 'Side 2'
      },
      {
        src: productImages('./c11-handles.jpg'),
        w: 1388,
        h: 990,
        title: 'Handles'
      },
      {
        src: productImages('./c11-internal.jpg'),
        w: 1388,
        h: 927,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C12',
    name: "Maple",
    thumbnail: productImages('./tbn-maple.jpg'),
    modelId: 2,
    items: [
      {
        src: productImages('./c12-side.jpg'),
        w: 1388,
        h: 971,
        title: 'Side'
      },
      {
        src: productImages('./c12-front.jpg'),
        w: 1388,
        h: 971,
        title: 'Front'
      },
      {
        src: productImages('./c5-handles.jpg'),
        w: 1388,
        h: 972,
        title: 'Handles'
      },
      {
        src: productImages('./c5-internal.jpg'),
        w: 1388,
        h: 955,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C13',
    name: "White",
    thumbnail: productImages('./tbn-white.jpg'),
    modelId: 1,
    items: [
      {
        src: productImages('./c13-side.jpg'),
        w: 1388,
        h: 971,
        title: 'Side'
      },
      {
        src: productImages('./c13-front.jpg'),
        w: 1388,
        h: 971,
        title: 'Front'
      },
      {
        src: productImages('./c13-handles.jpg'),
        w: 1388,
        h: 971,
        title: 'Handles'
      },
      {
        src: productImages('./c5-internal.jpg'),
        w: 1388,
        h: 955,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C14',
    name: "Oak",
    thumbnail: productImages('./tbn-oak.jpg'),
    modelId: 1,
    items: [
      {
        src: productImages('./c14-side.jpg'),
        w: 1388,
        h: 971,
        title: 'Side'
      },
      {
        src: productImages('./c14-front.jpg'),
        w: 1388,
        h: 971,
        title: 'Front'
      },
      {
        src: productImages('./c14-handles.jpg'),
        w: 1388,
        h: 972,
        title: 'Handles'
      },
      {
        src: productImages('./c5-internal.jpg'),
        w: 1388,
        h: 955,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C15',
    name: "Maple",
    thumbnail: productImages('./tbn-maple.jpg'),
    modelId: 1,
    items: [
      {
        src: productImages('./c15-side.jpg'),
        w: 1388,
        h: 971,
        title: 'Side'
      },
      {
        src: productImages('./c15-front.jpg'),
        w: 1388,
        h: 971,
        title: 'Front'
      },
      {
        src: productImages('./c15-handles.jpg'),
        w: 1388,
        h: 971,
        title: 'Handles'
      },
      {
        src: productImages('./c5-internal.jpg'),
        w: 1388,
        h: 955,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C16',
    name: "Rosewood",
    thumbnail: productImages('./tbn-mahogany.jpg'),
    modelId: 1,
    items: [
      {
        src: productImages('./c16-side.jpg'),
        w: 1388,
        h: 971,
        title: 'Side'
      },
      {
        src: productImages('./c16-front.jpg'),
        w: 1388,
        h: 971,
        title: 'Front'
      },
      {
        src: productImages('./c16-handles.jpg'),
        w: 1388,
        h: 971,
        title: 'Handles'
      },
      {
        src: productImages('./c5-internal.jpg'),
        w: 1388,
        h: 955,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C17',
    name: "White",
    thumbnail: productImages('./tbn-white.jpg'),
    modelId: 3,
    items: [
      {
        src: productImages('./c17-side.jpg'),
        w: 1388,
        h: 972,
        title: 'Side'
      },
      {
        src: productImages('./c17-front.jpg'),
        w: 1388,
        h: 972,
        title: 'Front'
      },
      {
        src: productImages('./c1-handles.jpg'),
        w: 1388,
        h: 972,
        title: 'Handles'
      },
      {
        src: productImages('./c5-internal.jpg'),
        w: 1388,
        h: 955,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C18',
    name: "Mahogany (half-lid)",
    thumbnail: productImages('./tbn-mahogany-half.jpg'),
    modelId: 4,
    items: [
      {
        src: productImages('./c18-side.jpg'),
        w: 1388,
        h: 925,
        title: 'Side'
      },
      {
        src: productImages('./c18-front.jpg'),
        w: 1388,
        h: 925,
        title: 'Front'
      },
      {
        src: productImages('./c2-handles.jpg'),
        w: 1388,
        h: 972,
        title: 'Handles'
      },
      {
        src: productImages('./c18-internal.jpg'),
        w: 1388,
        h: 925,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C19',
    name: "Gold",
    thumbnail: productImages('./tbn-gold.jpg'),
    modelId: 9,
    items: [
      {
        src: productImages('./c19-side.jpg'),
        w: 1388,
        h: 925,
        title: 'Side'
      },
      {
        src: productImages('./c19-front.jpg'),
        w: 1388,
        h: 925,
        title: 'Front'
      },
      {
        src: productImages('./c19-handles.jpg'),
        w: 1388,
        h: 925,
        title: 'Handles'
      },
      {
        src: productImages('./c19-internal.jpg'),
        w: 1388,
        h: 925,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C20',
    name: "Black",
    thumbnail: productImages('./tbn-black.jpg'),
    modelId: 9,
    items: [
      {
        src: productImages('./c20-side.jpg'),
        w: 1388,
        h: 925,
        title: 'Side'
      },
      {
        src: productImages('./c20-front.jpg'),
        w: 1388,
        h: 925,
        title: 'Front'
      },
      {
        src: productImages('./c20-handles.jpg'),
        w: 1388,
        h: 925,
        title: 'Handles'
      },
      {
        src: productImages('./c20-internal.jpg'),
        w: 1388,
        h: 925,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C21',
    name: "White",
    thumbnail: productImages('./tbn-white.jpg'),
    modelId: 9,
    items: [
      {
        src: productImages('./c21-side.jpg'),
        w: 1388,
        h: 925,
        title: 'Side'
      },
      {
        src: productImages('./c21-front.jpg'),
        w: 1388,
        h: 925,
        title: 'Front'
      },
      {
        src: productImages('./c21-handles.jpg'),
        w: 1388,
        h: 925,
        title: 'Handles'
      },
      {
        src: productImages('./c21-internal.jpg'),
        w: 1388,
        h: 925,
        title: 'Internal'
      },
      {
        src: productImages('./c21-corner.jpg'),
        w: 1388,
        h: 925,
        title: 'Corner'
      },
    ],
  },
  {
    id: 'C23',
    name: "Rosewood",
    thumbnail: productImages('./tbn-mahogany.jpg'),
    modelId: 10,
    items: [
      {
        src: productImages('./c23-side.jpg'),
        w: 1388,
        h: 925,
        title: 'Side'
      },
      {
        src: productImages('./c23-front.jpg'),
        w: 1388,
        h: 925,
        title: 'Front'
      },
      {
        src: productImages('./c23-handles.jpg'),
        w: 1388,
        h: 925,
        title: 'Handles'
      },
      {
        src: productImages('./c5-internal.jpg'),
        w: 1388,
        h: 955,
        title: 'Internal'
      },
    ],
  },
  {
    id: 'C24',
    name: "Rosewood with cross",
    thumbnail: productImages('./tbn-mahogany-cross.jpg'),
    modelId: 10,
    items: [
      {
        src: productImages('./c24-side.jpg'),
        w: 1388,
        h: 925,
        title: 'Side'
      },
      {
        src: productImages('./c24-front.jpg'),
        w: 1388,
        h: 925,
        title: 'Front'
      },
      {
        src: productImages('./c23-handles.jpg'),
        w: 1388,
        h: 925,
        title: 'Handles'
      },
      {
        src: productImages('./c5-internal.jpg'),
        w: 1388,
        h: 955,
        title: 'Internal'
      },
    ],
  },
];

export default productData;