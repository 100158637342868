import React from "react";
import modelData from './modelData';

function ModelPicker(props) {

  return (
    <div className="model-picker">
      
      <ul className="product-list">
        { modelData.map( function(object, i) {
          return <li key={i} className="product-item">
            <img src={ object.thumbnail } alt={ object.name } onClick={ () => props.onModelChange(object.submodels[0]) } />
          </li>
        })}
      </ul>
    </div>
    
  );
}

export default ModelPicker;