const productImages = require.context('../assets/images/products/', false, /.+-side\.jpg/);

const modelData = [
  {
    id: 1,
    name: 'Flatlid',
    submodels: [ 'C16', 'C15', 'C14', 'C13'],
    thumbnail: productImages('./c16-side.jpg'),
    material: 'MDF',
    sizes: {
      ext: [1980, 580, 375],
      int: [1920, 525, 330]
    }
  },
  {
    id: 10,
    name: 'Single raised',
    submodels: [ 'C23', 'C24'],
    thumbnail: productImages('./c23-side.jpg'),
    material: 'MDF',
    sizes: {
      ext: [1980, 580, 465],
      int: [1920, 525, 330]
    }
  },
  {
    id: 3,
    name: 'Double raised',
    submodels: [ 'C9', 'C5', 'C17'],
    thumbnail: productImages('./c9-side.jpg'),
    material: 'MDF',
    sizes: {
      ext: [1980, 580, 400],
      int: [1920, 525, 330]
    }
  },
  {
    id: 4,
    name: 'Grecian Urn',
    submodels: [ 'C2', 'C22', 'C1', 'C18'],
    thumbnail: productImages('./c2-side.jpg'),
    material: 'MDF',
    sizes: {
      ext: [2020, 700, 490],
      int: [1910, 600, 340]
    }
  },
  {
    id: 5,
    name: 'Long Casket',
    submodels: [ 'C3', 'C4'],
    thumbnail: productImages('./c3-side.jpg'),
    material: 'MDF',
    sizes: {
      ext: [2000, 640, 455],
      int: [1920, 560, 330]
    }
  },
  {
    id: 6,
    name: 'Grand Ashley',
    submodels: [ 'C8', 'C10'],
    thumbnail: productImages('./c8-side.jpg'),
    material: 'Solid Poplar',
    sizes: {
      ext: [2100, 700, 600],
      int: [1980, 580, 350]
    }
  },
  {
    id: 7,
    name: "Angel's Heart",
    submodels: [ 'C11'],
    thumbnail: productImages('./c11-side.jpg'),
    material: 'MDF',
    sizes: {
      ext: [450, 300, 200],
      int: [390, 230, 165]
    }
  },
  {
    id: 8,
    name: "Eco NSNA",
    description: "Photo of this product is indicative only, final shape and finishing may vary from time to time. Please contact supplier to confirm before purchase.",
    submodels: [ 'C7'],
    thumbnail: productImages('./c7-side.jpg'),
    material: 'MDF',
    sizes: {
      ext: [1980, 580, 375],
      int: [1920, 525, 330]
    }
  },
  {
    id: 9,
    name: "Everest Metal Casket",
    submodels: [ 'C19'],
    thumbnail: productImages('./c19-side.jpg'),
    material: '18/20 Ga Steel',
    sizes: {
      ext: [2020, 700, 490],
      int: [1910, 600, 340]
    }
  },
];

export default modelData;