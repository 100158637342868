import React, { useState, useEffect } from 'react';
import ProductImage from './Components/ProductImage';
import ColourSwitcher from './Components/ColourSwitcher';

import './App.css';
import ProductName from './Components/ProductName';
import ProductInfo from './Components/ProductInfo';
import ModelPicker from './Components/ModelPicker';

function App() {
  const [selProduct, setSelProduct] = useState("C1");

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => e.preventDefault() );
  })

  function handleProductChange(id) {
    setSelProduct(id);
  }

  return (
    <div className="App">
      <div id="main">
        <ProductImage productId={ selProduct } />
        <ModelPicker onModelChange={ handleProductChange } />
      </div>
      <div id="toolbar">
        <ColourSwitcher productId={ selProduct } onProductChange={ handleProductChange } />
        <ProductName productId={ selProduct } />
        <ProductInfo productId={ selProduct } />
      </div>
    </div>
  );
}

export default App;
